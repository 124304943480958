@font-face {
  font-family: 'Humane_Regular';
  src: url('./assets/fonts/Humane-Regular.woff');
}

@font-face {
  font-family: 'Humane_Light';
  src: url('./assets/fonts/Humane-Light.woff');
}

@font-face {
  font-family: 'Humane_ExtraBold';
  src: url('./assets/fonts/Humane-ExtraBold.woff');
}

/* Font / Font-size / Color */
a {
  text-decoration: none;
}

.h0 {
  font-size: 500px;
}

.h1 {
  font-size: 54px;
}

.h2 {
  font-size: 46px;
}

.h3 {
  font-size: 28px;
}

.h4 {
  font-size: 21px;
}

.nav_menu {
  font-size: 17px;
}

.nav_num {
  font-size: 10px;
}

.body_text {
  font-size: 20px;
  font-weight: 400;
}

.line_height {
  line-height: 140%;
}

.main {
  font-family: "darkmode-mono-off", sans-serif;
  font-style: normal;
  letter-spacing: -0.54px;
}

.main_light {
  font-family: "darkmode-mono-off", sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: -0.54px;
}

.main_italic {
  font-family: 'PPEditorialNew_Italic';
}

.main_lightitalic {
  font-family: 'PPEditorialNew_UltraItalic';
}

.title {
  font-family: 'Humane_ExtraBold';
  font-size: 16vw;
}

.title_text {
  font-family: 'Humane_Regular';
}

.body { 
  font-family: 'pretendard', sans-serif;
  color: #303030;
}

.bc_beige {
  background-color: #F0EBE5;
}

.bc_blue {
  background-color:#6D8EEB;
}

.bc_orange {
  background-color:#EB5B38;
}

.color_blue {
  color: #6D8EEB;
}

.color_green {
  color: #D4E32B;
}

.color_orange {
  color: #EB5B38;
}

.color_text {
  color: #5E5E5E;
}

.color_white {
  color: white;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

/* main css part */
html {
  overflow-x: hidden;
}

.project_a {
  color: white;
}

body {
  margin: 0;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* cursor: none; */
  cursor: url('./assets/img/cursor.svg') 12 12, auto;
}

section > div {
  height: 100vh;
}

::-webkit-scrollbar {
  display: none;
}

/* Nav Bar */
.NavBar {
  border-radius: 33px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.80);
  box-shadow: 0px 4px 4px 0px rgba(61, 61, 61, 0.10);
  backdrop-filter: blur(2px);
  position: fixed;
  top: 30px;
  left: 50%;
  padding: 10px 45px;
  display: flex;
  align-items: center;
  gap: 46.25px;
  align-self: stretch;
  z-index: 100;
  transition: padding 0.3s;
}

.externalNav {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  gap: 70px;
  display: flex;
  align-items: center;
  z-index: 100;
}

.externalNav_content {
  width: 55px;
  height: 55px;
  display: flex;
  font-size: 25px;
  border-radius: 33px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.80);
  box-shadow: 0px 4px 4px 0px rgba(61, 61, 61, 0.10);
  backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
  color: #5F6368;
}

.externalNav_content > .fa-arrow-up-right-from-square {
  font-size: 20px;
}

.nav_content {
  width: 24px;
  height: 24px;
}

.contact {
  cursor: pointer;
}

.homeNav {
  background-image: url('/src/assets/navbar/home.svg');
}

.aboutNav {
  background-image: url('/src/assets/navbar/person.svg');
}

.workNav {
  background-image: url('/src/assets/navbar/folder.svg');
}

.archiveNav {
  background-image: url('/src/assets/navbar/archive.svg');
}

.contactNav {
  background-image: url('/src/assets/navbar/contact.svg');
}

.common {
  display: flex;
  align-items: flex-start;
  gap: 18.75px;
}

.common > a, .contact {
  display: flex;
  padding: 5px;
  transform: scale(1.2);
  justify-content: center;
  align-items: center;
}

.common > a:first-child:hover .homeNav, .homeActive{
  background-image: url('/src/assets/navbar/hover_home.svg');
}

.common > a:nth-child(2):hover .workNav, .workActive{
  content: url('/src/assets/navbar/hover_folder.svg');
}

.common > a:nth-child(3):hover .aboutNav, .aboutActive{
  content: url('/src/assets/navbar/hover_person.svg');
}

.common > a:nth-child(4):hover .archiveNav, .archiveActive{
  content: url('/src/assets/navbar/hover_archive.svg');
}

.contact:hover > .contactNav, .contactActive {
  content: url('/src/assets/navbar/hover_contact.svg');
}

.navBarActive {
  padding: 10px 45px 180px 45px;
}

.contactInfo {
  display: none;
  width: 100%;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  flex-direction: column;
  padding: 0 30px;
}

.contactInfo > a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  color: #5E5E5E;
}

.contactInfo > a:not(:last-of-type) {
  border-bottom: 1px solid #5E5E5E;
}

.contactInfoActive {
  display: flex;
}

.tooltip {
  background-color: white;
  border-radius: 30px !important;
}

.link {
  position: fixed;
  padding: 0 45px;
  top: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}

.link_home {
  padding: 0;
}

.copyright_home {
  text-align: left;
  font-size: 15px;
}

.copyright {
  text-align: right;
  font-size: 15px;
}

.dropdown {
  font-size: 17px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;align-items: baseline;
  position: fixed;
  top: 35px;
  right: 45px;
  font-weight: 300;
  z-index: 150;
}

.mobile_nav {
  display: none;
  justify-content: space-between;
  align-items: center;
}

.mobile_nav_menu_title {
  width: auto;
  font-size: 17px;
}

.mobile_nav_drop {
  z-index: 100;
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EB5B38;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease, visibility 0s 0.5s; 
}

.mobile_nav_drop.open {
  height: 100vh;
  max-height: 100vh; 
  opacity: 1;
  visibility: visible; 
  transition: max-height 0.5s ease, opacity 0.5s ease;
}


.mobile_nav_list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile_nav_list > li > a {
  text-decoration: none;
  font-family: 'Humane_Regular';
}

.mobile_nav_menu {
  font-size: 100px;
}

.mobile_nav_menu:not(:nth-child(4)) {
  margin-bottom: 15px;
}

.bi-plus {
  transition: transform 0.3s;
}

.rotate {
  transform: rotate(45deg);
}

.dropdown_open {
  color: white;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: #555;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.loader {
  width: 80px;
  aspect-ratio: 1;
  border: 10px solid #000;
  box-sizing: border-box;
  background: 
    radial-gradient(farthest-side at right,#fff 98%,#0000) 20% 50%/40% 80%,
    radial-gradient(farthest-side at left ,#fff 98%,#0000) 80% 50%/40% 80%,
    #000;
  background-repeat: no-repeat;
  filter: blur(4px) contrast(10); 
  animation: l18 0.8s infinite alternate;
}

@keyframes l18 {
  0%,20% {background-position:20% 50%,80% 50%}
  100%   {background-position:80% 50%,20% 50%}
}

.loading-message {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

/* home page */
.home {
  height: 100vh;
  padding: 45px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 100px;
  overflow-y: scroll;
}

.home_left {
  width: 50%;
  float: left;
  text-align: right;
  box-sizing: border-box;
}

.home_right {
  height: 100%;
  width: 50%;
  float: right;
  box-sizing: border-box;
}

.home_box {
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px 20px;
  box-sizing: border-box;
  white-space: nowrap;
}

.status {
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  border: 3px solid #D4E32B;
  border-radius: 50px;
  padding: 6px 15px;
  text-decoration: none;
}

.point {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #D4E32B; 
  margin-right: 10px;
}

.status > div {
  color: #D4E32B;
  font-weight: 700;
}

.home_box_bold {
  text-align: right;
  margin-bottom: -40px;
  margin-right: 10px;
}

/* number table */
.number_table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: clamp(9px, 1.3vh, 12px);
  margin-bottom: 30px;
  position: relative;
}

.table_mob {
  display: none;
}

.link_home {
  padding: 0;
}

.number_table > tr > td:first-child {
  padding-right: 15px;
}

.number_table > tr > td:nth-child(2) {
  padding-left: 15px;
  color: #5E5E5E;
}

.number_table > tr > td:nth-child(2) > span {
  color: #5D5BED;
}

.table_line {
  position: absolute;
  border-right: 1px solid #EB5B38;
  height: 100%;
  left: 30px;
}

/* About  */
.about, .work {
  margin-bottom: 100px;
}

.about, .work, .archive {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about_profileImg {
  border-radius: 40px;
  overflow: hidden;
  width: 45%;
  height: 95%;
}

.about_profileImg > img {
  width: 100%;
  transform: scale(1.3);
}

.about_container {
  display: flex;
  padding: 25px;
}

.about_left, .about_right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_right {
  flex-direction: column;
  padding-right: 130px;
  font-size: 17px;
}

.about_title {
  margin-bottom: 15px;
}

.about_skills > .about_content {
  margin-bottom: 0;
}

.about_experience {
  width: 100%;
}

.about_experience > .about_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.about_table {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-top: 1px solid #5E5E5E;
  align-items: center;
}


.about_table:last-of-type {
  border-bottom: 1px solid #5E5E5E;
}

.about_table > div {
  display: flex;
  align-items: center;
}

.company_icon {
  width: 25px;
  margin-right: 10px;
}

.about_table > div > td:nth-child(2) {
  padding-right: 30px;
}

.about_table_2 {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
}

.about_table_2 > td:first-child {
  width: 30%;
}

.about_table_2 > td:last-child {
  width: 70%;
  line-height: 140%;
}

.about_content {
  margin-bottom: 60px;
}

/* Work */

.work_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 45px;
}

.work_preview {
  position: relative;
  font-size: 18px;
  width: 380px;
  margin-left: 90px;
}

.work_folderContent {
  position: relative;
  left: 60px;
}

.work_sectionDetail {
  position: relative;
  left: 15px;
  margin-top: 2px;
}

.work_folderName {
  margin-bottom: 30px;
}

.work_section {
  position: relative;
}

.work_section:not(:last-of-type) {
  margin-bottom: 60px;
}

.work_line {
  width: 35px;
  left: -40px;
  top: 12px;
  position: absolute;
  border-bottom: 1px solid #5E5E5E;
}

.work_line_1 {
  position: absolute;
  top: 12px;
  left: -25px;
  height: 24px;
  border-left: 1px solid #5E5E5E;
}

.work_line_2 {
  position: absolute;
  top: 12px;
  left: -25px;
  height: 48px;
  border-left: 1px solid #5E5E5E;
}

.work_line_d {
  position: absolute;
  top: 12px;
  left: -25px;
  border-left: 1px solid #5E5E5E;
}

.work_detail_line {
  width: 35px;
  left: -40px;
  top: 12px;
  position: absolute;
  border-bottom: 1px solid #5E5E5E;
}

.work_thumb {
  max-height: 500px;
  width: 800px;
  height: 50%;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}

.gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #000000c1 100%);
  background-blend-mode: multiply;
}

.work_name {
  font-size: 10vw;
  margin-bottom: -5px;
}

.work_info {
  position: absolute;
  bottom: 30px;
  left: 20px;
  color: white;
  width: 60%;
}


.view_case {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 30px;
  color: white;
  border: 1px solid white;
  transition: all 0.2s;
}

.work_thumb:hover .view_case {
  background-color: white;
  color: black;
}

.work_thumb > img {
  width: 100%;
}

.swiper-slide {
  display: flex !important; 
  height: 100vh;
}

/* Archive */

.archive_image {
  width: 500px;
  height: 260px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 80px;
  transition: box-shadow .3s ease;
}

.archive_image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.archive_image:hover {
  box-shadow: 0 4px 15px #0003;
}

.archive_title {
  font-size: 20px;
  color: white;
  margin-bottom: 15px;
}

.modal_content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  width: 74%;
  height: 80%;
}

.modal_content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

/* Project */
.project_thumbnail {
  margin-top: 100px;
  width: 100%;
  overflow: hidden;
  height: 450px;
}

.project_thumbnail img {
  width: 100%;
}

.img_adjustment {
  object-position: 0 -230px;
}

.project_intro {
  margin-top: 100px;
  padding: 0 300px;
  display: flex;
  flex-direction: column;
}

.project_subject {
  margin-bottom: 25px;
}

.project_content {
  margin-top: 40px;
  width: 55%;
  margin-left: auto;
}

.project_overview, .project_problem {
  width: 100%;
  padding: 0 300px;
  margin-top: 140px;
}

.overview_container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  border-radius: 30px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.50);
  backdrop-filter: blur(2px);
  padding: 40px 0px;
}

.overview_container > div > div {
  line-height: 140%;
}

.overview_title {
  margin-bottom: 10px;
}

.content_left {
  background-color: #e0e0e0;
  border-radius: 20px 50px 50px 50px;
  margin-top: 20px;
  padding: 50px;
}

.content_right {
  background-color: #4d57fe;
  border-radius: 20px 50px 50px 50px;
  margin-top: 20px;
  padding: 50px;
}

.content_right > span, .content_right > p, .content_right p strong {
  color: #fff;
}

.problem_content {
  display: flex;
  gap: 30px;
}

.problem_table > span {
  font-weight: 700;
}

.project_body {
  margin-top: 150px;
  padding: 0 400px;
}

.body_title {
  margin-bottom: 25px;
}

.body_content {
  padding-right: 50px;
}

.observation_photo {
  margin-top: 80px;
}

.observation_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.observation_container > img {
  width: 49%;
}

.observation_container2 > img {
  width: 100%;
  margin-top: 40px;
}

.project_hmw {
  font-size: 22px;
  line-height: 160%;
  text-align: center;
  margin-top: 150px;
  padding: 80px 0;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.50);
}

.body_imgs {
  margin-top: 50px;
}

.body_imgs > img {
  width: 100%;
  margin-top: 30px;
}

.project_table_bold {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.45px;
  margin-left: 13px;
}

.project_table_left {
  display: flex;
}

.project_table_right {
  padding-left: 130px;
}

.project_table {
  margin: 40px 0;
  border-spacing: 0 40px;
  border-collapse: separate;
}

.project_deliver {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  max-width: 100vw;
  overflow-x: scroll;
  overflow-y: clip;
  padding-bottom: 10px;
  scrollbar-width: none;
  width: 100%;
  padding: 0;
  margin-top: 0;
}

.project_deliver_container {
  display: flex;
  margin-top: 4vh;
  width: max-content;
}

.img_wrapper {
  display: inline-block;
  margin-right: 70px;
}

.img_wrapper > img {
  background-repeat: no-repeat;
  transition: transform .3s ease, box-shadow .3s ease;
  border-radius: 20px;
  width: 20vw;
}

.img_wrapper > img:hover {
  box-shadow: 0 4px 15px #0003;
  transform: scale(1.02);
}

.wrapper_laptop > img {
  border-radius: 10px;
  width: 60vw;
}

.deliver_box {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 140%;
}

.deliver_box:not(:last-of-type) {
  margin-bottom: 80px;
}

.deliver_box > div:first-child {
  margin-right: 100px;
}

.box_pc {
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
}


.deliver_desc  {
  margin-bottom: 15px;
}

.deliver_img {
  background-color: #1a1917;
  border-radius: 30px;
  display: flex;
  padding: .5vw;
  margin-left: auto;
}

.deliver_img > img {
  border-radius: 20px;
  width: 15vw;
}

.deliver_img_pc {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 10px;
}

.img_pc {
  object-fit: cover;
} 

.project_body:last-of-type {
  margin-bottom: 250px;
}

.swiper-pagination-bullet {
  background: #5E5E5E !important; 
  transition: all 0.3s ease;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  background: #22C182 !important; 
}

.swiper-button-prev {
  margin-left: 100px;
  color: #5E5E5E !important;
  box-sizing: border-box;
  padding: 38px;
  width: 30px !important;
  height: 30px !important;
  background-color: #ffffff94;
  border-radius: 50%;
  border: 1px solid white;
  transform: scale(0.5);
  user-select: none;
}

.swiper-button-next {
  margin-right: 100px;
  color: #5E5E5E !important;
  box-sizing: border-box;
  padding: 38px;
  width: 30px !important;
  height: 30px !important;
  background-color: #ffffff94;
  border-radius: 50%;
  border: 1px solid white;
  transform: scale(0.5);
  user-select: none;
}

.reflection {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.reflection_box {
  width: 33%;
  padding: 28px;
  border-radius: 15px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.80);
  box-shadow: 0px 4px 4px 0px rgba(61, 61, 61, 0.10);
  backdrop-filter: blur(2px);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.reflection_box b {
  font-size: 18px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* 어두운 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}





/* animation */
/* ----------------------------------------------
 * Generated by Animista on 2024-10-10 19:12:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

 @-webkit-keyframes 
 tracking-in-expand {0%{letter-spacing:-.2em;opacity:0}40%{opacity:.6}100%{opacity:1}}
 
 @keyframes 
 tracking-in-expand {0%{letter-spacing:-.2em;opacity:0}40%{opacity:.6}100%{opacity:1}}

 @-webkit-keyframes tracking-in-contract{0%{letter-spacing:1em;opacity:0}40%{opacity:.6}100%{letter-spacing:normal;opacity:1}}
 
 @keyframes tracking-in-contract{0%{letter-spacing:1em;opacity:0}40%{opacity:.6}100%{letter-spacing:normal;opacity:1}}


 /* Responsive */

 /* Extra Large Screens */
@media (min-width: 2560px) { 
  #work {
    height: 145vh;
  }

  .info_title {
    font-size: 30vw;
  }

  .footer_content {
    padding: 0 210px;
  }

  .project_role_detail {
    font-size: 28px;
  }

  .project_detail_result {
    padding: 0 180px;
  }

  .deliver_container {
    padding: 0 150px;
  }
}

/* Laptop */
@media (max-width: 1727px) {
  .number_table {
    gap: 9px;
  }

  .swiper-button-next, .swiper-button-prev {
    margin: 0;
  }
  
  .project_body {
    padding: 0 350px;
  }

  .about_profileImg {
    width: 320px;
    height: 500px;
  }

  .about_content {
    margin-bottom: 40px;
  }

  .project_deliver {
    padding: 0;
  }
}

@media (max-width: 1441px) {
  .project_content {
    width: 65%;
  }

  .project_body {
    padding: 0 280px;
  }
}

/* Medium screens */
@media (max-width: 1024px) {
  .NavBar {
    top: auto;
    bottom: 20px;
    padding: 8px 45px;
    gap: 30px;
    transform: translate(-50%, 0%);
  }

  .externalNav {
    transform: translate(-50%, 0%) scale(0.8);
    gap: 30px;
    bottom: 85px;
  }

  .navBarActive {
    padding: 180px 45px 8px 45px;
  }

  .contactInfo {
    top: 45%;
  }

  .common {
    gap: 8px;
  }

  .contact, .common > a {
    transform: scale(1);
  }

  .copyright {
    display: none;
  }

  .mobile_nav {
    display: flex;
  }

  .h2 {
    font-size: 28px; 
  }

  .h3 {
    font-size: 21px;
  }

  .h4 {
    font-size: 20px;
  }

  .home_right {
    height: 70vh;
    width: 100%;
    margin-top: 30px;
  }

  .home_left {
    display: none;
  }

  .table_mob {
    display: flex;
    margin-top: 15px;
    gap: 10px;
    font-size: 15px;
  }

  .link {
    padding: 0 20px;
    top: 25px;
  }

  .home {
    align-items: flex-start;
    flex-direction: column;
  }

  .copyright_home {
    display: none;
  }

  .home_box_bold {
    margin-bottom: -20px;
    font-size: 23vw;
  }

  /* about */
  .about_container {
    margin-top: 50px;
    gap: 80px;
    flex-direction: column;
  }

  .about_left, .about_right {
    width: 100%;
  }

  .about_right {
    padding: 0;
  }

  .about_skills > .about_content {
    margin-bottom: 60px;
  }

  /* work */
  .work_container {
    flex-direction: column;
  }

  .work > div:nth-child(2) {
    margin-top: 60px;
    margin-bottom: 60px;
}

  .work_thumb {
    height: 55vw;
    width: 100%;
  }

  .swiper-button-prev, .swiper-button-next {
    margin: 0;
  }  

  .work_folderContent {
    display: flex;
    left: 20px;
  }

  .work_preview {
    width: 100%;
    margin: 0;
    margin-top: 30px;
  }

  .work_section {
    width: 25%;
    font-size: 16px;
  }

  .work_line {
    width: 16px;
    left: -20px;
  }

  .work_line_1, .work_line_2, .work_line_d {
    left: -13px;
    display:none;
  }

  .work_detail_line {
    left: -18px;
    width: 15px;
  }

  .work_sectionDetail {
    left: 5px;
  }

  .work_section:not(:last-of-type) {
    margin-right: 50px;
  }

  .view_case {
    transform: scale(0.9);
    bottom: 20px;
    right: 10px;
  }
  /* project */
  .project_thumbnail {
    height: 23vh;
  }

  .project_intro {
    padding: 0 50px;
    margin-top: 50px;
  }

  .project_content {
    width: 100%;
  }

  .project_overview, .project_problem {
    padding: 0 20px;
    margin-top: 140px;
  }

  .problem_content {
    gap: 0px;
    flex-direction: column;
  }

  .project_hmw {
    padding: 40px 0;
  }

  .project_body {
    padding: 0 20px;
  }

  .body_content {
    padding: 0;
  }

  .project_table_right {
    padding-left: 40px;
  }

  .deliver_img {
    border-radius: 15px;
    padding: 1vw;
  }

  .deliver_img > img {
    border-radius: 10px;
    width: 20vw;
  }

  .img_wrapper {
    margin-right: 40px;
  }

  .img_wrapper > img {
    border-radius: 10px;
    width: 50vw;
  }

  .wrapper_laptop > img {
    width: 80vw;
  }
}

/* Small screens */
@media (max-width: 768px) {
  .body, .main {
    font-size: 15px;
  }

  .home_right {
    width: 100%;
    margin-top: 30px;
  }

  .home_left {
    display: none;
  }

  .home {
    padding: 30px 20px;
  }

  .table_mob {
    font-size: 14px;
  }

  .copyright_home {
    display: none;
  }

  .home_box_bold {
    margin-bottom: 0;
  }

  .title {
    font-size: 25vw;
  }

  .status {
    border: 2px solid #D4E32B;
  }

  .view_case::before {
    content: "view case study 👆";
  }

}

@media (max-width: 575px) {
  .view_case {
    font-size: 60px;
    padding: 0;
    bottom: 10px;
    border: 0;
  }

  .view_case::before {
    content: "👆";
  }

  .view_case:hover {
    background-color: transparent;
    border: 0;
  }

  .work_thumb:hover .view_case {
    background-color: transparent;
    border: 0;
  }


  .work_info {
    bottom: 10px;
  }

  .work_info > div:last-of-type {
    display:none;
  }
}

/* Mobile screens */
@media (max-width: 425px) {
  .externalNav {
    transform: scale(0.8);
    gap: 30px;
    bottom: 85px;
    right: 0;
    left: auto;
  }

  .home {
    justify-content: flex-start;
  }

  .home, .about, .work {
    margin-bottom: 50px;
  }

  .home_right {
    margin-top: 30px;
    height: 55vh;
  }

  .NavBar {
    display: flex;
    width: 90%;
    justify-content: center;
  }

  .common {
    gap: 18.75px;
  }

  /* About */
  .about_profileImg {
    border-radius: 20px;
  }

  .about_container {
    gap: 60px;
  }

  .about_table > div > td:first-child {
    display: none;
  }

  .about_table > div > td:nth-child(2) {
    padding-right: 20px;
  }

  .about_table_2 > td:first-child {
    width: 50%;
  }

  /* Work */
  .work {
    height: fit-content;
  }

  .work > div:nth-child(2) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .work_thumb {
    height: 55vw;
  }

  .work_container {
    padding: 20px;
    margin-bottom: 30px;
  }

  /* Archive */

  .archive_image {
    height: 200px;
  }

  .mySwiper {
    padding: 15px !important;
  }

  /* Project */
  .overview_container {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .overview_container > div {
    width: 45%;
  }

  .project_overview, .project_problem {
    margin-top: 100px;
  }

  .project_body, .project_hmw {
    margin-top: 100px;
  }

  .deliver_box > div:first-child {
    margin-right: 40px;
  }

  .project_deliver {
    padding: 0;
    margin: 0;
  }

  .reflection {
    flex-direction: column;
  }

  .reflection_box {
    width: 100%;
  }

  .box_pc {
    gap: 10px;
  }
}

.cursor {
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  transition: transform 0.1s ease;
  transform: translate(-50%, -50%);
}



